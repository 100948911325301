<template>
  <v-container>
    <v-row>
      <div class="general-details">
        <TabNavigation :tabs="tabs" :currentDispatcherId="this.$route.params.id"/>
        <div class="details-container" v-if="dispatcherDetails || loading" style="margin-top: 8px">
          <base-material-card color="primary" style="width: 100%;">
            <template v-slot:heading>
              <div class="text-h3 font-weight-light">General Details</div>
            </template>
            <v-card elevation="0" class="pa-2">
              <v-form v-if="!loading">
                <v-row class="no-gutters py-2" style="margin-bottom: 25px">
                  <v-col cols="12" md="6" v-for="field in fields" :key="field.key" style="padding: 10px;">
                    <h5 class="">{{ field.label }}:</h5>
                    <v-text-field 
                      :value="getFieldValue(field.key)"
                      class="purple-input"
                      style="margin-top: -14px; margin-right: 30px" 
                      readonly
                    />
                  </v-col>
                </v-row>
                <v-col v-if="companyLogo" cols="12" md="6" style="padding: 10px;">
                  <h5 class="mb-2">Company Logo:</h5>
                  <img :src="companyLogo" alt="Company Logo" style="max-width: 20rem; height: auto;" />
                </v-col>
              </v-form>
              <v-overlay v-else absolute color="rgba(255, 255, 255, 0.8)">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
              </v-overlay>
            </v-card>
          </base-material-card>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import TabNavigation from '@/components/TabNavigation.vue';
import { getDispatcherById } from '@/services/admin';

export default {
  components: {
    TabNavigation
  },
  data() {
    return {
      tabs: [
        { name: 'General', path: '/dispatcher-general-details' },
        { name: 'Bookings', path: '/dispatcher-bookings' },
        { name: 'Drivers', path: '/dispatcher-drivers' },
        { name: 'Vehicles', path: '/dispatcher-vehicles' },
        { name: 'Transactions', path: '/dispatcher-transactions' }
      ],
      dispatcherDetails: null, // To store dispatcher details
      fields: [
        { label: 'First Name', key: 'firstName' },
        { label: 'Last Name', key: 'surName' },
        { label: 'Phone', key: 'phone' },
        { label: 'Email', key: 'email' },
        { label: 'Role', key: 'role' },
        // { label: 'Registered With Stripe', key: 'registeredWithStripe' },
        // { label: 'Status', key: 'status' },
        { label: 'Verified', key: 'isVerified' },
        { label: 'Banned', key: 'banned' },
        { label: 'Email Verified', key: 'isEmailVerified' },
        { label: 'Company Name', key: 'company.name' },
        { label: 'Total Trucks', key: 'company.totalTrucks' },
        { label: 'Total Drivers', key: 'company.totalDrivers' }
      ],
      companyLogo: null, // To store company logo URL
      loading: false // To indicate loading state
    };
  },
  created() {
    this.fetchDispatcherDetails();
  },
  methods: {
    async fetchDispatcherDetails() {
      try {
        this.loading = true;
        const dispatcherId = this.$route.params.id;

        const response = await getDispatcherById(dispatcherId);
        this.dispatcherDetails = response.data;

        if (this.dispatcherDetails.company && this.dispatcherDetails.company.logo) {
          this.companyLogo = this.dispatcherDetails.company.logo;
        }
      } catch (error) {
        console.error('Error fetching dispatcher details:', error);
      } finally {
        this.loading = false;
      }
    },
    getFieldValue(key) {
      const keys = key.split('.');
      let value = this.dispatcherDetails;
      for (const k of keys) {
        if (value && value.hasOwnProperty(k)) {
          value = value[k];
        } else {
          value = '';
          break;
        }
      }

      // Convert boolean values to user-friendly labels
      if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No';
      }

      return value;
    }
  }
}
</script>

<style scoped>
.general-details {
  width: 100%;
  padding: 20px;
}

.details-container {
  display: flex;
  justify-content: center;
}
</style>
